<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import moment from "moment";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      opening_balances: [],
      transactions: [],
      period: {},
      colsePeriod: {},
      isEndPeriod: false,
      AccountingPeroids: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchAccountingPeroids: null,
      AccountingPeroidsSearchMode: false,
      searchModel: "",
      state: state,
      editPeriod: {},
    };
  },
  methods: {
    addAccountingPeroids() {
      this.http.post("accounting-peroids", this.period).then(() => {
        this.get(this.page);
      });
    },

    showEditAccountingPeroids(app) {
      this.editPeriod = app;
    },
    editAccountingPeroids() {
      this.http
        .put("accounting-peroids", this.editPeriod.id, this.editPeriod)
        .then(() => {
          this.get(this.page);
        });
    },
    search() {
      this.AccountingPeroidsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("accounting-peroids/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.AccountingPeroids = res.data;
          console.log("######## files", this.AccountingPeroids);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.AccountingPeroidsSearchMode = false;
      this.get(this.page);
    },

    getAccountingPeroids() {
      this.http.get("accounting-peroids").then((res) => {
        this.AccountingPeroids = res.data;
      });
    },
    colseAccountingPeroids() {
      this.http.post("accounting-peroids", this.colsePeriod).then((res) => {
        if (res.status) {
          this.get(1);
        }
      });
    },
    compareDates(date) {
      var now = moment();
      if (date == now) return true;
      return false;
    },

    get(page) {
      console.log(page);
      this.http
        .post("accounting-peroids/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.AccountingPeroids = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getAccountingPeroids();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.accounting_periods.text')"
    />
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          v-if="AccountingPeroids.length == 0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light" style="background-color: #2a3042 !important">
          <th scope="col">#</th>
          <th scope="col">{{ $t("accounting_peroids.start_date") }}</th>
          <th scope="col">{{ $t("accounting_peroids.end_date") }}</th>
          <th scope="col">{{ $t("accounting_peroids.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in AccountingPeroids" :key="app" class="">
          <td>{{ index + 1 }}</td>
          <td>{{ app.start_date }}</td>
          <td>{{ app.end_date }}</td>
          <td class="d-flex">
            <button
              class="btn btn-primary mx-1"
              @click="showEditAccountingPeroids(app)"
              href="javascript: void(0);"
              data-bs-toggle="modal"
              data-bs-target="#editModal"
              role="button"
            >
              {{ $t("popups.edit") }}
            </button>
            <button
              class="btn btn-danger"
              href="javascript: void(0);"
              data-bs-toggle="modal"
              data-bs-target="#colsePeriodModal"
              role="button"
            >
              {{ $t("accounting_peroids.close") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--Start register Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addAccountingPeroids()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.add") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row purchase-container">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="period.start_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="period.end_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End register Modal-->

    <!--Start edit Modal-->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editAccountingPeroids()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edit") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row purchase-container">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="editPeriod.start_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="editPeriod.end_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                type="submit"
                data-bs-dismiss="modal"
                class="btn btn-primary"
              >
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End edit Modal-->

    <!-- Start colse periods modal -->
    <div
      class="modal fade"
      id="colsePeriodModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="colseAccountingPeroids()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.close_period") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row purchase-container">
                <div class="row d-flex align-items-center mb-2">
                  <p>{{ $t("popups.next_period_info") }}</p>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="colsePeriod.start_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("accounting_peroids.start_date")
                    }}</label>
                    <input
                      v-model="colsePeriod.end_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('accounting_peroids.start_date')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.colse") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End close periods modal -->

    <div>
      <!--   Apps  pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  font-size: 15px;
}
</style>
